"use client";
import { redirect } from "next/navigation";
import { useEffect } from "react";

const HomePage = () => {
  useEffect(() => {
    redirect("/order");
  }, []);

  return;
  <>
    <head>
      <meta name="robots" content="noindex, nofollow" />
    </head>
  </>;
};

export default HomePage;
